import { BrowserRouter, Routes, Route } from "react-router-dom";
import WalletSetup from "./components/wallet/WalletSetup";
import Transactions from "./components/transaction/Transactions";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WalletSetup />} />
        <Route path="/transactions" element={<Transactions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
