import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Tag,
  message,
  Row,
  Col,
  Tooltip,
} from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { walletBackendInstance } from "../../utils/axiosInstance";
import styles from "./Transactions.module.css";
import { CSVLink } from "react-csv";
import _ from "lodash";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const pageSize = 10;

  const fetchTransactions = (page) => {
    const walletId = localStorage.getItem("walletId");
    const skip = (page - 1) * pageSize;
    walletBackendInstance
      .get(`/transactions?walletId=${walletId}&limit=${pageSize}&skip=${skip}`)
      .then((response) => {
        setTransactions(response.data.transactions);
        setTotalTransactions(response.data.totalCount);
      })
      .catch((error) => {
        const errMsg =
          _.get(error, "response.data.error", error.message) ||
          "Error occurred";
        message.error(errMsg);
      });
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination);
  };
  const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  const columns = [
    {
      label: "Transaction ID",
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
    },
    {
      label: "Amount",
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amt) => {
        amt = String(amt);
        if (amt.includes("-")) {
          return `-$${amt.split("-").pop()}`;
        }
        return `$${amt}`;
      },
    },
    {
      label: "Closing Balance",
      title: "Closing Balance",
      dataIndex: "balance",
      key: "balance",
      render: (bal) => `$${bal}`,
    },
    {
      label: "Type",
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => {
        return (
          <Tag color={type === "CREDIT" ? "green" : "volcano"} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      label: "Date",
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleString(userLocale, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      label: "Transaction Note",
      title: "Transaction Note",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div className={styles.container}>
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={2} style={{ margin: 0 }}>
            Transaction History
          </Typography.Title>
        </Col>

        {transactions && transactions.length > 0 && (
          <Col>
            <Tooltip title="Download CSV">
              <CSVLink
                data={transactions}
                headers={columns}
                filename={"statement.csv"}
              >
                <Button
                  type="primary"
                  className="downloadBtn"
                  style={{ marginLeft: "16px" }}
                >
                  <CloudDownloadOutlined />
                </Button>
              </CSVLink>
            </Tooltip>
          </Col>
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={transactions}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalTransactions,
          onChange: handleTableChange,
          showTotal: (total) => (
            <div className={styles.paginationContainer}>
              <Button
                type="primary"
                href="/"
                style={{ marginRight: "50vw", textDecoration: "none" }}
              >
                Back to Wallet
              </Button>
              <span>Total {total} transactions</span>
            </div>
          ),
        }}
        style={{ margin: "20px 0" }}
      />
      {(!transactions || transactions.length === 0) && (
        <Button
          type="primary"
          href="/"
          style={{ marginRight: "30vw", textDecoration: "none" }}
        >
          Back to Wallet
        </Button>
      )}
    </div>
  );
};

export default Transactions;
