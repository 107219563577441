import { useState } from "react";
import { Input, Button, Typography, message, Form, InputNumber } from "antd";
import styles from "./WalletSetup.module.css";
import { walletBackendInstance } from "../../utils/axiosInstance";
import Payment from "../payment/Payment";
import _ from "lodash";

const WalletSetup = () => {
  const [form] = Form.useForm();
  console.log(form.getFieldValue(), "fghj");

  const [walletId, setWalletId] = useState(localStorage.getItem("walletId"));

  const handleCreateWallet = async (values) => {
    try {
      const {
        data: { id: newWalletId },
      } = await walletBackendInstance.post("/setup", {
        name: values.username,
        balance: values.initialBalance || 0,
      });
      localStorage.setItem("walletId", newWalletId);
      setWalletId(newWalletId);
      message.success(`Wallet created successfully!`);
    } catch (error) {
      const errMsg =
        _.get(error, "response.data.error", error.message) || "Error occurred";
      message.error(errMsg);
    }
  };

  return (
    <div className={styles.container}>
      {walletId ? (
        <Payment />
      ) : (
        <div className={styles.createWallet}>
          <Typography.Title level={3}>Create Wallet</Typography.Title>
          <Form
            form={form}
            onFinish={handleCreateWallet}
            layout="vertical"
            initialValues={{ username: "", initialBalance: "" }}
          >
            <Form.Item
              name="username"
              label="Username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="initialBalance"
              label="Initial Balance (optional)"
              rules={[
                {
                  type: "number",
                  min: 1,
                  message: "Please enter a valid balance.",
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Initial Balance (optional)"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
              >
                Create Wallet
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default WalletSetup;
