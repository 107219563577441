import axios from "axios";

const walletBackendInstance = axios.create({
    baseURL: "https://wallet-backend-jjbw.onrender.com",
    timeout: 5000, // Set a timeout of 5 seconds for all requests
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
});

export { walletBackendInstance }