import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Typography,
  Switch,
  Form,
  InputNumber,
  message,
  Row,
  Col,
  Avatar,
  Tooltip,
} from "antd";
import { UserOutlined, CopyOutlined } from "@ant-design/icons";
import { walletBackendInstance } from "../../utils/axiosInstance";
import styles from "./Payment.module.css";
import _ from "lodash";

const Payment: React.FC = () => {
  const [form] = Form.useForm();
  const [balance, setBalance] = useState<number>(0);
  const walletId = localStorage.getItem("walletId");
  const [username, setUsername] = useState<string>("");
  const [isCredit, setIsCredit] = useState<boolean>(false);

  useEffect(() => {
    walletBackendInstance
      .get(`/wallet/${walletId}`)
      .then((response) => {
        setBalance(response.data.balance);
        setUsername(response.data.name);
      })
      .catch((error) => {
        const errMsg =
          _.get(error, "response.data.error", error.message) ||
          "Error occurred";
        message.error(errMsg);
      });
  }, [walletId]);

  const handleTransaction = async (values: any) => {
    try {
      const { transactionAmount, transactionNote } = values;
      const payload = {
        amount: isCredit ? transactionAmount : -transactionAmount,
        description: transactionNote,
      };
      const response = await walletBackendInstance.post(
        `/transact/${walletId}`,
        payload
      );
      setBalance(response.data.balance);
      message.success("Transaction successful!");
      form.resetFields();
      setIsCredit(false);
    } catch (error: any) {
      const errMsg =
        _.get(error, "response.data.error", error.message) || "Error occurred";
      message.error(errMsg);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  };

  return (
    <>
      <div
        className={styles.walletDetails}
        style={{
          border: "1px solid #1677ff",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f9f9f9",
          marginBottom: "20px",
        }}
      >
        <Typography.Title
          level={3}
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          WALLET DETAILS
        </Typography.Title>
        <Row>
          <Col>
            <Typography.Text
              style={{ fontFamily: "Poppins, sans-serif", fontSize: "18px" }}
            >
              ID: {walletId}
            </Typography.Text>
          </Col>
          <Col>
            <Tooltip title="Copy Wallet ID">
              <Button
                type="link"
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(walletId as string)}
              />
            </Tooltip>
          </Col>
        </Row>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
            marginBottom: "16px",
          }}
        >
          <Avatar
            style={{ backgroundColor: "#87d068", marginRight: "8px" }}
            icon={<UserOutlined />}
            size="large"
          />
          <Typography.Text
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
            }}
          >
            {username}
          </Typography.Text>
        </div>
        <Typography.Text
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Balance: {`$${balance}`}
        </Typography.Text>
      </div>
      <div>
        <Form form={form} layout="vertical" onFinish={handleTransaction}>
          <Form.Item
            name="transactionAmount"
            label={
              <span
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
              >
                Transaction Amount($)
              </span>
            }
            rules={[
              { required: true, message: "Please enter a valid amount" },
              {
                type: "number",
                min: 1,
                message: "Amount must be greater than 1$",
              },
            ]}
          >
            <InputNumber
              placeholder="Transaction Amount"
              className={styles.input}
              style={{
                fontFamily: "Poppins, sans-serif",
                width: "100%",
                padding: "8px",
              }}
            />
          </Form.Item>

          <Form.Item
            name="isCredit"
            label={
              <span
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
              >
                Transaction Type
              </span>
            }
            valuePropName="checked"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Switch
              onChange={() => {
                setIsCredit(!isCredit);
              }}
            />
            <span
              style={{
                marginLeft: 10,
                fontFamily: "Poppins, sans-serif",
                fontWeight: "500",
              }}
            >
              {isCredit ? "Credit" : "Debit"}
            </span>
          </Form.Item>

          <Form.Item
            name="transactionNote"
            label={
              <span
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
              >
                Transaction Note <small>(max 50 characters)</small>
              </span>
            }
            rules={[
              {
                max: 50,
                message: "Transaction note cannot exceed 50 characters",
              },
            ]}
          >
            <Input
              placeholder="Transaction Note"
              className={styles.input}
              style={{ fontFamily: "Poppins, sans-serif", padding: "8px" }}
            />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.button}
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    padding: "8px 16px",
                  }}
                >
                  Execute Transaction
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="default"
                  href="/transactions"
                  className={styles.link}
                  style={{
                    marginLeft: "16px",
                    fontFamily: "Poppins, sans-serif",
                    padding: "8px 16px",
                  }}
                >
                  View Transactions
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Payment;
